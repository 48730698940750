import React, {FunctionComponent} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Twitter } from "../../images/icons/twitterBlue";
import Accessibility from "../Accessibility";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

import "./styles.scss";

const Tweet: FunctionComponent = (props) => {
  return (
    <section className="rx-tweet">
      <Container fluid>
        <Row>
          <Col md={12}>
            <div style={{ padding: "50px 0", background: "rgb(0, 128, 128)" }}>
              <div className="rx-tweet--content">
                <div className="rx-tweet--header">
                  <Twitter />
                  <h3>{props.heading}</h3>
                </div>
                <div className="rx-tweet--body">
                  <div>
                    <BlockContent blocks={props._rawContent} />
                  </div>
                </div>
                <div className="rx-tweet--cta">
                  <a
                    className="btn-primary rx-tweet--cta-button"
                    href={`${props.twiiterShareUrl}${encodeURIComponent(props.content[0].children[0].text)}`}
                    rel="noreferrer noopener"
                  >
                    {props.ctaLabel}
                    <Accessibility location={""} openNewWindow={true} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Tweet;